import Address from "@/resources/Address.js";
import {userStorage} from "@/services/storage/userStorage.js";
import store from "@/store/store.js";
import User from "@/resources/User.js";
import AuthenticationToken from "@/resources/AuthenticationToken.js";
import axios from "axios";
import {apiUrl} from "@/configuration/api.js";
import RegisterData from "@/resources/RegisterData.js";

class UserService {
  constructor() {
    this.userStorage = userStorage;
  }

  // address
  async setUserAddress(value) {
    try {
      await this.userStorage.set('user_address', value);

      await store.dispatch('updateAddressStatus');
    } catch (error) {
      console.error('Failed to set user address:', error);
    }
  }

  async getUserAddress() {
    try {
     const address = await this.userStorage.get('user_address');

      if(!address){
        return null;
      }

      return new Address(address);
    } catch (error) {
      console.error('Failed to get user address:', error);
      return '';
    }
  }

  async setUser(value) {
    try {
      const user = new User(value);
      await this.userStorage.set('user', user);

      await store.dispatch('updatedUser');
    } catch (error) {
      console.error('Failed to set authenticated user:', error);
    }
  }

  async getUser() {
    try {
      return new User(await this.userStorage.get('user')) || new User();
    } catch (error) {
      console.error('Failed to get authenticated user:', error);
      return null;
    }
  }

  async setProvider(value) {
    try {
      const user = await this.getUser();

      user.auth_provider = value;

     await this.setUser(user);
    } catch (error) {
      console.error('Failed to set provider:', error);
    }
  }

  async setAuthTokenData(value) {
    try {
      const authToken = new AuthenticationToken(value);
      await this.userStorage.set('auth_token_data', authToken);
    } catch (error) {
      console.error('Failed to set auth token data:', error);
    }
  }

  async getAuthTokenData() {
    try {
      const tokenData = await this.userStorage.get('auth_token_data');
      return new AuthenticationToken(tokenData) || new AuthenticationToken();
    } catch (error) {
      console.error('Failed to get auth token data:', error);
      return null;
    }
  }

  async isAuthenticated() {
    try {
      const user = await this.getUser();
      const token = await this.getAuthTokenData();

      return (user.id && token.access_token) || false;
    } catch (error) {
      console.error('Failed to check if user is authenticated:', error);
      return false;
    }
  }

  async removeUser() {
    try {
      await this.userStorage.set('user', new User())
    } catch (error) {
      console.error('Failed to remove authenticated user:', error);
    }
  }

  async removeAuthTokenData() {
    try {
      await this.userStorage.set('auth_token_data', new AuthenticationToken())
    } catch (error) {
      console.error('Failed to remove auth token data:', error);
    }
  }

  async setRegisterData(value) {
    try {
      const registerData = new RegisterData(value);

      console.log('registerData', registerData);
      await this.userStorage.set('register_data', registerData);
      await store.dispatch('updatedRegisterData');
    } catch (error) {
      console.error('Failed to set register data:', error);
    }
  }

  async getRegisterData() {
    try {
      return new RegisterData(await this.userStorage.get('register_data')) || new RegisterData();
    } catch (error) {
      console.error('Failed to get register data:', error);
      return null;
    }
  }

  async removeRegisterData() {
    try {
      await this.userStorage.set('register_data', new RegisterData())
    } catch (error) {
      console.error('Failed to remove register data:', error);
    }
  }

  async updateAPIUser(payload){
    try {

      const user = await this.getUser();

      user.first_name = payload.first_name;
      user.last_name = payload.last_name;
      user.phone = payload.phone;

      await axios.post(apiUrl('customer/account/update'), payload);

      await this.setUser(user);

      await store.dispatch('updatedUser');
    }catch (error){
      console.error('Failed to update user:', error);
    }
  }

  async setCurrentOrder(order) {
    try {
      const currentOrders = await this.getCurrentOrders();
      if (!currentOrders.some(o => o.nr === order.nr)) {
        currentOrders.push(order);
        await this.userStorage.set('current_orders', currentOrders);
      }
    } catch (error) {
      console.error('Failed to set current order:', error);
    }
  }

  async getCurrentOrders() {
    try {
      return await this.userStorage.get('current_orders') || [];
    } catch (error) {
      console.error('Failed to get current orders:', error);
      return [];
    }
  }

  async getCurrentOrder() {
    try {
      const orders = await this.getCurrentOrders();
      return orders.length > 0 ? orders[orders.length - 1] : null;
    } catch (error) {
      console.error('Failed to get current order:', error);
      return null;
    }
  }

  async removeOrder(orderNr) {
    try {
      const currentOrders = await this.getCurrentOrders();
      const filteredOrders = currentOrders.filter(order => order.nr !== orderNr);
      await this.userStorage.set('current_orders', filteredOrders);
    } catch (error) {
      console.error('Failed to remove order:', error);
    }
  }

  async clearCurrentOrder() {
    try {
      await this.userStorage.set('current_orders', []);
    } catch (error) {
      console.error('Failed to clear current orders:', error);
    }
  }

  async setPasswordForgotEmail(email) {
    try {
      await this.userStorage.set('password_forgot_email', email);
    } catch (error) {
      console.error('Failed to set password forgot email:', error);
    }
  }

  async getPasswordForgotEmail() {
    try {
      return await this.userStorage.get('password_forgot_email') || null;
    } catch (error) {
      console.error('Failed to get password forgot email:', error);
      return null;
    }
  }

  async setUserEmailVerificationStatus(status) {
    try {
      const userObj = await this.getUser();

      userObj.email_verified = status;

      await this.setUser(userObj);
    } catch (error) {
      console.error('Failed to set email verification status:', error);
    }
  }
}

export const userService = new UserService();
