<template>
  <div class="r2e-input" style="height: 6.1rem">
    <label v-if="label" :for="id">{{ label }}</label>
    <div class="input-field search-field">
      <vue-google-autocomplete
        :id="id"
        ref="places"
        v-model="location"
        :api-key="apiKey"
        types="address"
        country="be"
        class="google-search-location"
        :placeholder="placeholder ? placeholder : __t('app.banner.search.placeholder')"
        @placechanged="setAddressBySearchInput"
        @keypress.enter="enterClicked"
      />
      <button class="search" @click="emit('submit')">
        <Icon name="search" />
      </button>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, defineEmits, defineProps, watch } from 'vue';
  import { getAddressString, setAddress } from "@/modules/order/services/AddressService";
  import { __t } from "@/globals";
  import VueGoogleAutocomplete from "vue-google-autocomplete";
  import session from "@/modules/session";
  import Icon from "@/ui/icon/Icon.vue";

  const props = defineProps({
    placeholder: { type: String, required: false, default: null },
    autoSubmit: { type: Boolean, required: false, default: true },
    label: { type: String, required: false, default: null },
    id: { type: String, required: true } // Add this line
  });

  const emit = defineEmits(['canSearch', 'submit', 'setAddress']);

  const apiKey = process.env.GOOGLE_MAPS_WEB_API_KEY;
  const location = ref(session.getters['getAddress'] ? getAddressString(session.getters['getAddress']) : '');

  const places = ref(null);

  onMounted(() => {
    if (places.value) {
      places.value.$el.value = location.value;
    }
  });

  watch(location, (newLocation) => {
    if (places.value) {
      places.value.$el.value = newLocation;
    }
  });

  const setAddressBySearchInput = (addressData) => {
    if (props.autoSubmit) {
      setAddress(addressData);
    } else {
      emit('setAddress', addressData);
    }
    emit('canSearch');
    emit('submit');
  };

  const enterClicked = () => {
    emit('submit');
  };
</script>

<style lang="scss">
.r2e-input {
  label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.6;
    color: var(--r2e-secondary);
    padding-bottom: 5px;
  }

  .input-field {
    position: relative;
  }

  .search-field {
    height: 6.1rem;
    position: relative;

    .google-search-location {
      background: transparent;
      outline: none;

      background: var(--r2e-secondary-100);
      color: var(--r2e-secondary);
      font-size: 1.4rem;
      width: 100%;
      font-weight: 500;
      height: 6.1rem;
      border: 1px solid transparent;
      border-radius: 4px;
      padding: 1.9rem 1.6rem;
      transition: var(--effect);

      &::placeholder {
        color: var(--r2e-secondary-200);
        font-weight: 500;
        opacity: 1;
      }

      &:hover {
        border: 1px solid var(--r2e-secondary-200);
      }

      &:focus {
        border: 1px solid var(--r2e-secondary);
        box-shadow: 0 0 0 1px var(--r2e-secondary);
      }
    }

    button.search {
      background-color: transparent;
      position: absolute;
      top: calc(50% - 1rem);
      right: var(--margin-m);
      z-index: 2;

      i svg {
        width: 2rem;
        height: 2rem;
        color: var(--r2e-secondary-200);
      }
    }
  }
}

[data-theme='dark'] {
  .r2e-input {
    label {
      color: var(--r2e-secondary-200);
    }

    .search-field {
      .google-search-location {
        background: var(--r2e-secondary-500);
        color: var(--r2e-white);

        &::placeholder {
          color: var(--r2e-secondary-200);
        }

        &:focus {
          border: 1px solid var(--r2e-secondary-200);
          box-shadow: 0 0 0 1px var(--r2e-secondary-200);
          caret-color: var(--r2e-secondary-200);
        }
      }
    }
  }
}

@keyframes blink-caret {
  from, to { caret-color: transparent; }
  50% { caret-color: var(--r2e-secondary); }
}
</style>
