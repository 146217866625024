import {preferencesStorage} from "@/services/storage/preferenceStorage.js";
import store from "@/store/store.js";

class PreferenceService {
  constructor() {
    this.preferencesStorage = preferencesStorage;
  }

  async setLanguage(value) {
    try {
      await this.preferencesStorage.set('app_locale', value);
    } catch (error) {
      console.error('Failed to set language:', error);
    }
  }

  async getLanguage() {
    try {
      return await this.preferencesStorage.get('app_locale') || 'nl';
    } catch (error) {
      console.error('Failed to get language:', error);
      return 'en';
    }
  }

  setLocale(value) {
    return localStorage.setItem('locale', value);
  }

  getLocale(){
    return localStorage.getItem('locale') || 'nl';
  }

  async setReady2Help(value) {
    try {
      await this.preferencesStorage.set('ready2help', value);

      await store.dispatch('updateOrderPreferences');
    } catch (error) {
      console.error('Failed to set organisation:', error);
    }
  }

  async getReady2Help() {
    try {
      return await this.preferencesStorage.get('ready2help') || null;
    } catch (error) {
      console.error('Failed to get organisation:', error);
      return false;
    }
  }

  async setArrivalTimingPreference(value) {
    try {
      if(['now', 'other'].includes(value)){
        await this.preferencesStorage.set('arrival_timing_preference', value);
        await store.dispatch('updateOrderPreferences');
      }else {
        console.error('Invalid arrival timing preference value:', value);
      }
    } catch (error) {
      console.error('Failed to set delivery time:', error);
    }
  }

  async getArrivalTimingPreference() {
    try {
      return await this.preferencesStorage.get('arrival_timing_preference') || 'now';
    } catch (error) {
      console.error('Failed to get delivery time:', error);
      return null;
    }
  }

  async setPreferredTimeOfArrival(date, time){
    try {
      await this.preferencesStorage.set('preferred_time_of_arrival', date + ' ' + time);
      await store.dispatch('updateOrderPreferences');
    } catch (error) {
      console.error('Failed to set preferred time of arrival:', error);
    }
  }

  async getPreferredTimeOfArrival() {
    try {
      return await this.preferencesStorage.get('preferred_time_of_arrival') || null;
    } catch (error) {
      console.error('Failed to get preferred time of arrival:', error);
      return null;
    }
  }

  async getPaymentMethod() {
    try {
      return await this.preferencesStorage.get('payment_method') || 'online';
    } catch (error) {
      console.error('Failed to get payment method:', error);
      return null;
    }
  }

  async setPaymentMethod(value) {
    try {
      await this.preferencesStorage.set('payment_method', value);
      await store.dispatch('updateOrderPreferences');
    } catch (error) {
      console.error('Failed to set payment method:', error);
    }
  }

  // theme
  async setTheme(value) {
    try {
      await this.preferencesStorage.set('app_theme', value);
    } catch (error) {
      console.error('Failed to set theme:', error);
    }
  }

  async getTheme() {
    try {
      return await this.preferencesStorage.get('app_theme') || 'light';
    } catch (error) {
      console.error('Failed to get theme:', error);
      return 'light';
    }
  }

  // locationPermission
  async setLocationPermission(value) {
    try {
      await this.preferencesStorage.set('location_permission', value);
      await store.dispatch('updateAppPreferences');
    } catch (error) {
      console.error('Failed to set location permission:', error);
    }
  }

  async getLocationPermission() {
    try {
      return await this.preferencesStorage.get('location_permission') || 'denied';
    } catch (error) {
      console.error('Failed to get location permission:', error);
      return 'denied';
    }
  }

  // notificationPermission
  async setNotificationPermission(value) {
    try {
      await this.preferencesStorage.set('notification_permission', value);
      await store.dispatch('updateAppPreferences');
    } catch (error) {
      console.error('Failed to set notification permission:', error);
    }
  }

  async getNotificationPermission() {
    try {
      return await this.preferencesStorage.get('notification_permission') || null;
    } catch (error) {
      console.error('Failed to get notification permission:', error);
      return 'denied';
    }
  }

  //trackingPermission
  async setTrackingPermission(value) {
    try {
      await this.preferencesStorage.set('tracking_permission', value);
      await store.dispatch('updateAppPreferences');
    } catch (error) {
      console.error('Failed to set tracking permission:', error);
    }
  }

  async getTrackingPermission() {
    try {
      return await this.preferencesStorage.get('tracking_permission') || null;
    } catch (error) {
      console.error('Failed to get tracking permission:', error);
      return 'denied';
    }
  }

  // privacyPermission
  async setPrivacyPermission(value) {
    try {
      await this.preferencesStorage.set('privacy_permission', value);
      await store.dispatch('updateAppPreferences');
    } catch (error) {
      console.error('Failed to set privacy permission:', error);
    }
  }

  async getPrivacyPermission() {
    try {
      return await this.preferencesStorage.get('privacy_permission') || null;
    } catch (error) {
      console.error('Failed to get privacy permission:', error);
      return 'denied';
    }
  }

  // testMode
  async setTestModeDismissed(value) {
    try {
      await this.preferencesStorage.set('test_mode_dismissed', value);
    } catch (error) {
      console.error('Failed to set test mode:', error);
    }
  }

  async getTestModeDismissed() {
    try {
      return await this.preferencesStorage.get('test_mode_dismissed') || false;
    } catch (error) {
      console.error('Failed to get test mode:', error);
      return false;
    }
  }

  async setAppVersion(value) {
    try {
      await this.preferencesStorage.set('app_version', value);
    } catch (error) {
      console.error('Failed to set app version:', error);
    }
  }

  async getAppVersion() {
    try {
      return await this.preferencesStorage.get('app_version') || null;
    } catch (error) {
      console.error('Failed to get app version:', error);
      return '';
    }
  }

  async setOnboardingCompleted(value) {
    try {
      await this.preferencesStorage.set('onboarding_completed', value);
    } catch (error) {
      console.error('Failed to set onboarding completed:', error);
    }
  }

  async getOnboardingCompleted() {
    try {
      return await this.preferencesStorage.get('onboarding_completed') || false;
    } catch (error) {
      console.error('Failed to get onboarding completed:', error);
      return false;
    }
  }


}

export const preferenceService = new PreferenceService();
