import SocialAuthService from "@/services/auth/SocialAuthService.js";
import {FirebaseAuthentication} from "@capacitor-firebase/authentication";


export default class SocialAuthNativeService extends SocialAuthService {
  async loginWithGoogle() {
    await FirebaseAuthentication.signOut();

    const resp = await FirebaseAuthentication.signInWithGoogle({
      prompt: 'select_account',
    });

    return resp.credential.accessToken;
  }

  async loginWithFacebook() {
    await FirebaseAuthentication.signOut();
    const resp = await FirebaseAuthentication.signInWithFacebook(
      {prompt: 'select_account'}
    );

    return resp.credential.accessToken;
  }
}
